<template>
<!-- Loader -->
  <div  class="flex w-full items-center justify-center max-h-sm">
    <LoadingData v-if="isLoading" :message="'Logging in..'" />
    <div v-else-if="responseError" class="error">{{ responseError }}</div>
  </div>
<!-- Login Form -->
  <div v-if="challenge && challenge._id && challenge.verificationToken" class="w-full flex-grow p-1">
    <div class="w-full whitespace-normal truncate text-sm font-semibold p-1 text-left border-b">Multifactor Authorization</div>
    <div class="w-full whitespace-normal truncate p-1 text-left text-sky">Please enter the access code you received.</div>
    <div class="w-full p-1 flex items-center border-b">
      <div class="w-full max-w-36 flex p-1 items-center">
        <input v-model.trim="authCode" type="password" v-on-enter="()=>canVerifyMFA ? verifyMFA() :null" class="w-full" maxlength="6" placeholder="Access Code" :disabled="isLoading">
      </div>
      <div class="flex-grow flex items-center">
        <div class="p-1 pr-3">
          <button class="bn-icon-only" :disabled="isLoading || !canVerifyMFA" @click="verifyMFA()">
            <SaveIcon />
          </button>
        </div>
        <div class="p-1" >
          <button class="bn-icon-only" :disabled="isLoading" @click="challenge = {};trustDevice = false">
            <XIcon />
          </button>
        </div>
      </div>
        <div v-if="fingerPrint" class="p-1" >
          <button class="bn-clear" :disabled="isLoading" @click="trustDevice = !trustDevice" :class="trustDevice ? 'text-green-700' : 'text-red-700 opacity-60'">
            <div>Trust Device</div>
          </button>
        </div>
    </div>
  </div>
  <form v-else @submit.prevent="onSubmit" novalidate>
    <div class="flex flex-col w-full flex-nowrap space-y-4 px-4 pt-3">
      <div class="w-full flex flex-col space-y-1">
        <!-- Email Addresss Input -->
        <div class="flex flex-row w-full items-center space-x-1 text-sm">
          <AtSymbolIcon class="h-7 w-7" :class="error.email ? 'text-red-700': null"/>
          <input type="email" name="email" placeholder="Email" class="flex-grow flex h-6 px-1 border-0 border-b-2 border-neutral-700 dark:border-neutral-300 focus:ring-0 rounded-none bg-neutral-50 dark:bg-neutral-900" @input="error.email = ''"
            :class="error.email ? 'border-red-700' : null"
            v-model.trim="email"
          />
        </div>
        <div v-if="error.email" class="error w-full text-sm px-8">
          {{ error.email }}
        </div>
      </div>
      <!-- Password Input -->
      <div class="w-full flex flex-col space-y-1">
        <div class="flex flex-row w-full items-center space-x-1 text-sm">
          <LockClosedIcon class="h-7 w-7" :class="error.password ? 'text-red-700': null" />
          <input type="password" name="password" placeholder="Password" class="bg-opacity-0 flex-grow flex h-6 px-1 border-0 border-b-2 border-neutral-700 dark:border-neutral-300 focus:ring-0 rounded-none" @input="error.password = ''"
           :class=" error.password ? 'border-red-700' : null"
            v-model.trim="password"
            autocomplete="off"
          />
        </div>
        <div v-if="error.password" class="error w-full px-8 text-sm">
          {{ error.password }}
        </div>
      </div>
      <button class="bn-solid p-1 self-center w-36" type="submit" :disabled="isLoading">
          Login
      </button>
    </div>
  </form>
</template>

// <script>
import router from "../router/index";
import { ref, inject, onMounted, computed } from "vue";
import axios from "axios";
import {generateDeviceFingerprint} from '@/shared'

import {AtSymbolIcon,LockClosedIcon,SaveIcon} from "@heroicons/vue/outline"
import {XIcon} from '@heroicons/vue/solid'
import api from '@/api';
import onEnter from '@/components/ui/directives/onEnter'
import { useRoute} from 'vue-router'

export default {
  directives:{
    'on-enter':onEnter
  },
  name: "LoginDialog",
  components: {
    LockClosedIcon,
    AtSymbolIcon,
    SaveIcon,
    XIcon
  },
  setup() {
    const global = inject("global");
    const { login, authenticated } = global;
    const challenge = ref({})
    const authCode = ref(null)
    const isLoading = ref(false);
    const responseError = ref(null);
    const email = ref(null);
    const password = ref(null);
    const error = ref({
      email: "",
      password: "",
    });
    const reLogin = ref(false);
    const fingerPrint = ref(null)
    const trustDevice = ref(false)

    const route = useRoute()
    const redirectRoute = route.query?.redirect || null

    onMounted(()=>{
      getDeviceFingerprint()
      localStorage.getItem('lastLogin') ? email.value = localStorage.getItem('lastLogin') : null
    })

    const canVerifyMFA = computed(()=>{
      return authCode.value && authCode.value.length > 5 && challenge.value._id && challenge.value.verificationToken
    })

    const getDeviceFingerprint = async ()=>{
      fingerPrint.value = await generateDeviceFingerprint()
    }

    // Validate email and password format on submit
    const onSubmit = async () => {
      error.value = {
        email: "",
        password: "",
      }
      !email.value || !email.value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
        ? (error.value.email = "Please enter a valid email")
        : (error.value.email = "");

      !password.value
        ? (error.value.password = "Please enter a password")
        : (error.value.password = "");

      !error.value.email && !error.value.password ? submitLogin() : "";
    };

    // Login with provided credentials
    const submitLogin = async () => {
      responseError.value = ''
      isLoading.value = true;
      let values = { email: email.value, password: password.value,fingerPrint:fingerPrint.value };
      password.value = "";
      if (authenticated.value.loggedIn) {reLogin.value = true;}

      await axios
        .post("/api/v1/auth/login", values)
        .then((response) => {
          if(response.data?.type === 'MFA') {
            authCode.value = null
            challenge.value = response.data
          } else if(typeof response.data === 'object' && response.data._id && response.data.token) {
            authenticateUser(response.data)
            //localStorage.setItem("other-tab-login", "");
            ////localStorage.clear();
            //localStorage.setItem("lastLogin",response.data.email)
            //responseError.value = "";
            //const redirectTarget =
            //  sessionStorage.getItem("redirectTarget") || "/";
            //if (sessionStorage.getItem("redirectTarget")) {
            //  sessionStorage.removeItem("redirectTarget");
            //}
            //login(response.data)
            //if (!reLogin.value) {
            //  router.push(redirectTarget);
            //}
            return;
          } else {
              responseError.value = "Problem with login";
          }
        })
        .catch((err) => {
          responseError.value = err.response?.data?.error || err.message;
        })
        .finally(()=>{
          isLoading.value = false
        })
    };

    const verifyMFA = async()=>{
      isLoading.value = true
      let body = {
        userId:challenge.value._id,
        verificationToken:challenge.value.verificationToken,
        code:authCode.value
      }
      trustDevice.value ? body.trustDeviceFingerPrint = fingerPrint.value : null
      await api
      .post('auth/loginMFA',body)
      .then(res=>{
        if(typeof res.data === 'object' && res.data._id && res.data.token)  {
          authenticateUser(res.data)
        }
      })
      .catch(err=>
        responseError.value = err.response?.data?.error || err.message
      )
      .finally(()=>{
        isLoading.value = false
      })
    }

    const authenticateUser = (user)=>{
      localStorage.setItem("other-tab-login", "");
      localStorage.setItem("lastLogin",user?.email)
      responseError.value = "";
      const redirectTarget = redirectRoute || sessionStorage.getItem("redirectTarget") || "/";
      if (sessionStorage.getItem("redirectTarget")) {sessionStorage.removeItem("redirectTarget")}
      login(user)
      if (!reLogin.value) {router.push(redirectTarget)}
    }

    return {
      // user input
      email,
      password,
      // functions
      onSubmit,
      submitLogin,
      isLoading,
      responseError,
      login,
      error,
      challenge,
      authCode,
      verifyMFA,
      canVerifyMFA,
      trustDevice,
      fingerPrint
    };
  },
};
</script>
