import { createRouter, createWebHistory } from 'vue-router'
import global from '@/global'
const {authenticated,requestAuth,closeSideBar} = global



const routes = [

  //Unauthenticated Routes
    {
      path: '/login',
      name: 'Login',
      component: ()=>import('@/views/Login.vue'),
      meta: {
        title: 'DLSTracker - Login'
      },
      props: true,
      beforeEnter: async (to, from, next) => {
        const authData = await requestAuth();
        const redirectTarget = sessionStorage.getItem('redirectTarget') || '/';
        sessionStorage.removeItem('redirectTarget');

        if (authData) {
          console.log('Logging in with received authentication data.');
          next(redirectTarget);
        } else if (authenticated.value.loggedIn) {
          console.log('Already authenticated. Redirecting.');
          next(redirectTarget);
        } else {
          console.log('No authentication found. Proceeding to Login.');
          next();
        }
      }
    },
    {
      path: '/forgotpassword',
      name: 'Forgot Password',
      component: ()=>import('@/views/ForgotPassword.vue'),
      beforeEnter(to, from, next) {
        console.log('beforeEnter guard executed for /forgotpassword');
        console.log('Authenticated:', authenticated.value.loggedIn);
        if(!authenticated.value.loggedIn) {next()} else { next({ name: 'Home' })}
      }
    },
    {
      path: '/resetpassword/:resetToken',
      name: 'Reset Password',
      component: ()=>import('@/views/ResetPassword.vue'),
      beforeEnter(to, from, next) {
        !authenticated.value.loggedIn ? next() : next({ name: 'Home' })
      }
    },
    {
      path: '/verifyaccount/:verifytoken',
      name: 'VerifyAccount',
      component: () => import('../components/VerifyAccount.vue'),
      beforeEnter(to, from, next) {
        !authenticated.value.loggedIn ? next() : next({ name: 'Home' })
      }
    },
    {
      path:'/external/:projectId/:secureCode',
      name:'External',
      component: ()=>import('@/components/external/ExternalEquipmentTracker.vue'),
      beforeEnter(to,from,next) {
        !authenticated.value.loggedIn ? next() : next({ name: 'Home' })
      }
    },
    {
      path:'/external/roomTracker/:projectId/:secureCode',
      name: 'ExternalRoomTracker',
      component: ()=>import('@/components/external/ExternalRoomTracker.vue'),
      beforeEnter(to, from, next) {
        !authenticated.value.loggedIn ? next() : next({ name: 'Home' })
      }
    },
    {
      path:'/external/photos/:projectId/:secureCode',
      name: 'ExternalProjectPhotos',
      component: ()=>import('@/components/external/ExternalProjectPhotos.vue'),
      beforeEnter(to, from, next) {
        !authenticated.value.loggedIn ? next() : next({ name: 'Home' })
      }
    },
//Home, About & Error
  {
    path: '/',
    name: 'Home',
    component: ()=>import('../components/home/Home.vue'),
    meta: { title: 'Home' },
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: ()=>import('@/components/Error.vue'),
    props: true,
    beforeEnter(to, from, next) {
      if (to.params.status) { next() }
      else next({ name: 'Home' })
    }
  },
//Account Specific Info
{
  path: '/myAccount',
  name: 'My Account',
  component: () => import('@/components/myAccount/MyAccount')
},
{
  path: '/pto',
  name: 'RequestPTO',
  component: () => import('../components/timeManagement/PTO/PTO.vue'),
  meta:{
    title:'PTO Requests',
    accessControl:{pto:1}
  }
},
//{
//  path: '/timesheets',
//  name: 'Timesheets',
//  component: () => import('../components/timeManagement/timesheets/TimeSheetsUser.vue'),
//  meta:{
//    primeOnly:true,
//    timesheet:true
//  }
//},
{
  path: '/timecards',
  name: 'Timesheets',
  component: () => import('../components/timeManagement/timecards/Timecards.vue'),
  meta:{
    primeOnly:true,
    timesheet:true
  }
},
{
  path:'/payroll',
  name:'Payroll',
  component: ()=> import('../components/timeManagement/timecards/Payroll.vue'),
  meta:{
    primeOnly:true,
    accessControl:{payroll:1}
  }
},
{
  path:'/specialRates',
  name:'Special Rates',
  component: ()=> import('../components/timeManagement/timecards/SpecialRates.vue'),
  meta:{
    primeOnly:true,
    accessControl:{payroll:1}
  }
},
{
  path:'/approveTimecards',
  name:'Approve Timesheets',
  component:()=>import('@/components/timeManagement/timecards/TimecardApproval.vue'),
  meta: {
    title:'Approve Timesheets',
    primeOnly:true,
    accessControl:{approveTimesheets:1}
  }
},
//{
//  path:'/approveTimesheets',
//  name:'Approve Timesheets',
//  component :()=> import('@/components/timeManagement/timesheets/TimesheetApproval.vue'),
//  meta: {
//    title:'Approve Timesheets',
//    primeOnly:true,
//    accessControl:{approveTimesheets:1}
//  }
//},
{
  path: '/timeclock',
  name: 'Time Clock',
  component: ()=> import ('../components/timeManagement/WorkTime.vue'),
},
{
  path: '/timeclockAdmin',
  name: 'Timeclock Admin',
  component:()=> import('@/components/timeManagement/AllUsersClockedTime.vue'),
  meta:{
    title:'Timeclock Admin',
    accessControl:{approveTimesheets:1}
  }
},
{
  path:'/myTimeclocks',
  name:'My Timeclock',
  component:()=>import('@/components/timeManagement/AllUsersClockedTime.vue'),
  props:{userOnly:true},
  meta:{
    timesheet:true
  }
},
{
  path:'/timeclockReport',
  name:'Timeclock Report',
  component:()=>import('@/components/timeManagement/TimeClockWeeklyReport.vue'),
  meta:{
    title:'Timeclock Report',
    primeOnly:true,
    accessControl:{approveTimesheets:1}
  }
},
{
  path:'/ptoPlans',
  name:'PTO Plans',
  component:()=>import('@/components/timeManagement/ptoPlans/PTOPlans.vue'),
  meta:{
    title:'PTO Plans',
    primeOnly:true,
    accessControl:{payroll:1}
  }
},
{
  path:'/employeePTOInfo',
  name:'Employee Info',
  component:()=>import('@/components/timeManagement/employeeInfo/EmployeePTOInfo.vue'),
  meta:{
    title:'Employee Info',
    primeOnly:true,
    accessControl:{payroll:1}
  }
},
{
  path:'/sickTime',
  name:'Sick Time',
  component:()=>import('@/components/timeManagement/sickTime/sickTime'),
  meta:{
    title:'Sick Time Plans',
    primeOnly:true,
    accessControl:{payroll:1}
  }
},
{
  path:'/employeeSickTime',
  name:'Employee Sick Time',
  component:()=>import('@/components/timeManagement/employeeInfo/EmployeeSickTimeInfo.vue'),
  meta:{
    title:'Employee Sick Time Info',
    primeOnly:true,
    accessControl:{payroll:1}
  }
},
{
  path:'/holidays',
  name:'Company Holidays',
  component:()=>import('@/components/timeManagement/holidays/Holidays.vue'),
  meta:{
    title:'Company Holidays',
    primeOnly:true,
    accessControlAny:{payroll:1,scheduling:1}
  }
},
//Users
  {
    path: '/users',
    name: 'Users',
    component: () => import('../components/users/Users.vue'),
    meta: {
      title: 'Users',
      accessControl:{'manageAccounts':1}
    }
  },
//Projects
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../components/projects/ViewProjects.vue'),
    meta: {
      title: 'Projects',
      role: 0,
      accessControl:{projects:1}
    }
  },
  {
    path: '/project/:projectId/:noReturn?',
    name: 'Project',
    component:()=>import('@/components/projects/Project.vue'),
    meta: {
      title: 'Project',
      accessControl: {projects:1}
    }
  },
  {
    path:'/newProject',
    name: 'New Project',
    component:()=>import('../components/projects/newProject/newProject.vue'),
    meta:{
      title:'New Project',
      primeOnly:true,
      accessControl:{projects:3}
    }
  },
  {
    path:'/projectSearch',
    name: 'Project Search',
    component:()=>import('../components/projects/search/ProjectSearch.vue'),
    meta:{
      title:'Search Projects',
      primeOnly:true,
      accessControl:{projects:3}
    }
  },
//Invoicing
  {
    path:'/invoices',
    name:'Invoices',
    component:()=>import('@/components/invoices/Invoices.vue'),
    meta:{
      title:'Invoices',
      primeOnly:true,
      accessControlAny:{projects:4,invoices:1}
    }
  },
  {
    path:'/openInvoices',
    name:'Open Invoices',
    component:()=>import('@/components/invoices/OpenInvoicesByCustomer.vue'),
    meta:{
      title:'Open Invoices',
      primeOnly:true,
      accessControlAny:{projects:4,invoices:1}
    }
  },
//Receiving and Equipment
  {
    path: '/findEquipment',
    name: 'findEquipment',
    component: () => import('../components/equipment/SearchEquipment.vue'),
    meta: {
      title: 'Find Equipment',
      role: 3
    }
  },
  {
    path: '/receiving',
    name: 'Receiving',
    component: () => import('../components/equipment/ReceiveEquipment.vue'),
    meta: {
      title: 'Receiving',
      accessControl:{receiving:1}
    }
  },
  {
    path: '/equipmentCatalog',
    name: 'EquipmentCatalog',
    component: () => import('../components/equipment/EquipmentCatalog'),
    meta: {
      title: 'Equipment Catalog',
      accessControl:{receiving:1}
    }
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import('../components/equipment/Tools.vue'),
    meta: {
      title: 'Tools',
      primeOnly:true,
      accessControl:{warehouseQueue:1}
    }
  },
  {
    path: '/vehicleManagement',
    name: 'Vehicles',
    component: () => import('../components/vehicles/VehicleManagement.vue'),
    meta: {
      title: 'Vehicles',
      primeOnly:true,
      accessControl:{warehouseQueue:1}
    }
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import('../components/organizations/Organizations.vue'),
    meta: {
      title: 'Organizations',
      accessControl: {organizations:1}
    }
  },
  {
    path:'/shippingRequests',
    name:'Warehouse Requests',
    component: () => import('../components/warehouse/ShippingRequests.vue'),
    meta: {
      title: 'Warehouse Requests',
      accessControl:{warehouseQueue:1}
    }
  },
  {
    path:'/rackBuildRequests',
    name:'Rack Requests',
    component: () => import('@/components/warehouse/rackBuilds/RackBuildRequests.vue'),
    meta: {
      title: 'Rack Requests',
      accessControl:{rackQueue:1}
    }
  },
  {
    path:'/invoiceRequests',
    name:'Invoice Requests',
    component: () => import('../components/invoices/InvoiceRequests.vue'),
    meta: {
      title: 'Invoice Requests',
    }
  },
  {
    path:'/scanner',
    name:'Scanner',
    component:()=>import('../components/warehouse/Scanner.vue'),
    meta:{
      title:'QR Scanner',
      primeOnly:true
    }
  },
  {
    path:'/printLabels',
    name:'Labels',
    component:()=>import('../components/equipment/PrintLabels.vue'),
    meta:{
      title:'Labels',
      accessControlAny:{warehouseQueue:1,rackQueue:1}
    }
  },
  {
    path: '/DataManagement',
    name: 'DataManagement',
    component: () => import('../components/applicationData/DataManagement.vue'),
    props: true,
    meta: {
      title: 'Data Management'
    }
  },
  {
    path: '/scheduleResources',
    name: 'Schedule Resources',
    component: ()=>import('@/components/scheduling/resources/Schedule.vue'),
    meta: { 
      title: 'Schedule Resources',
      primeOnly:true,
      accessControl:{scheduling:1}
    },
    props: true
  },
  {
    path:'/shipmentSchedule',
    name:'Shipment Schedule',
    component:()=>import('@/components/warehouse/schedule/ShipmentSchedule.vue'),
    props:{inModal:false},
    meta:{
      title:'Shipment Schedule',
      primeOnly:true,
      accessControlAny:{warehouseQueue:1,projects:2}
    }
  },
  {
    path:'/importProject',
    name:'New Project Import',
    component:()=>import('@/components/import/project/NewProjectImport.vue'),
    meta:{
      title:'New Project Import',
      primeOnly:true,
      accessControl:{quotes:3}
    }
  },
  {
    path:'/importTabs',
    name:'Tab Import',
    component:()=>import('@/components/import/tabImport/TabImport.vue'),
    meta:{
      title:'Tab Import',
      primeOnly:true,
      accessControl:{quotes:3}
    }
  },
  {
    path:'/scheduleTest',
    name:'Schedule Test',
    component:()=>import('@/components/scheduling/resources/ScheduleTest.vue'),
    meta:{
      title:'Schedule Test',
      primeOnly:true,
      accessControl:{scheduling:1}
    }
  },
  {
    path:'/signage/warehouse',
    name:'WarehouseDashboard',
    component:()=>import('@/components/digitalSignage/WarehouseDigitalSignage.vue'),
    meta:{
      title:'Warehouse Dashboard'
    }
  },
  {
    path:'/adminTesting',
    name:'Admin Testing',
    component:()=>import('@/components/adminTesting/AdminTesting.vue'),
    meta:{
      title:'Admin Testing',
      primeOnly:true
    }
  },
  {
    path:'/reports/PTOReports',
    name:'PTO Reports',
    component:()=>import('@/components/reports/pto/PtoOrganizationReport.vue'),
    meta:{
      title:'PTO Reports',
      primeOnly:true,
      accessControlAny:{warehouseQueue:1,approveTimesheets:1}
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: ()=>import('@/views/NotFound.vue')
  },
  {
    path:'/projectValueReport',
    name:'Project Value',
    component:()=>import('@/components/reports/project/ProjectValue.vue'),
    meta:{
      title:'Project Value Report',
      primeOnly:true,
      accessControl:{projects:5}
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  hash: false,
  routes
})


// maintains global auth state on refresh, redirects to intended target after login & nav guard for authenticated users
router.beforeEach(async (to, from, next) => {
  // save original target if not authenticated
  const redirectTarget = to.fullPath || '/'
  // sets display title of document
  document.title = to.meta.title || to.name
  closeSideBar()
  //if (sessionStorage.getItem("refresh") === sessionStorage.getItem("refresh")) { await authOnRefresh() }
  window.scrollTo(0, 0) //set scroll position to top when changing router views

  // redirects to login if not authenticated unless target is in toList (no auth required)
  const unauthenticatedRoutes = ['Login', 'Forgot Password', 'Reset Password', 'VerifyAccount','External','ExternalRoomTracker','ExternalProjectPhotos','WarehouseDashboard','Not Found']

  if (!unauthenticatedRoutes.includes(to.name) && !authenticated.value.loggedIn) {
    sessionStorage.setItem("redirectTarget", redirectTarget)
    next({ name: 'Login',query: { redirect: redirectTarget }}); // Send redirectTarget as a query param
  } else {
    next();
  }
})

// navigation guard to verify user role has access to route based on route->meta->role(level)
router.beforeEach((to, from, next) => {
  if (to.meta.primeOnly) {
    !authenticated.value.organization?.orgType?.includes('primary')
    ? next({ name: 'Error', params: { status: 403, message: 'Access Denied: Internal Only.' }, query: to.query })
    : next()
  } else if (to.meta.accessControl) {
    !Object.keys(to.meta.accessControl).every((key)=>{return authenticated.value.role.accessModel[key] >= to.meta.accessControl[key]})  
    ? next({ name: 'Error', params: { status: 403, message: 'Access Denied.' } , query: to.query}) : next()
  } else if (to.meta.accessControlAny) {
    (Object.keys(to.meta.accessControlAny).filter((a)=> authenticated.value.role.accessModel[a] >= to.meta.accessControlAny[a]).length === 0) 
    ? next({ name: 'Error', params: { status: 403, message: 'Access Denied at Access Control Any.' }, query: to.query }) : next()
  } else if (to.meta.timesheet && to.meta.timesheet === true) {
    !authenticated.value.timesheet ? next({ name: 'Error', params: { status: 403, message: 'Access Denied.' }, query: to.query }) : next()
  } else {
    next()
  }
})


export default router
